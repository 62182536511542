import PropTypes from "prop-types";
import React from "react";
import LinkTo from "./elements/LinkTo";
import styled from "@emotion/styled";
import color, { themeColors } from "../styles/colors";
import { navigation } from "../styles/typography";
import { ReactComponent as Logo } from "../images/vectors/logo.svg";
import { ReactComponent as FacebookIcon } from "../images/vectors/icon-facebook.svg";
import { ReactComponent as InstagramIcon } from "../images/vectors/icon-instagram.svg";
import { ReactComponent as TwitterXIcon } from "../images/vectors/icon-x.svg";
import { transparentize } from "polished";
import FooterAccordionItem from "./FooterAccordionItem";
import { RichText } from "./elements/Section";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import FluidImagerX from "./elements/FluidImagerX";
import {
	spacingRestricted,
	screen,
	container,
	gridColumnByMargin,
	fluidRestricted,
	grid
} from "../styles/mixins";

const FooterEl = styled.footer`
	width: 100%;
	background-color: ${(props) => themeColors[props.colorTheme].bg};
	color: ${color("white")};
	${spacingRestricted("padding-top", "xxl")};
	${spacingRestricted("padding-bottom", "xs")};

	@media ${screen("md")} {
		padding-bottom: 0;
	}
`;

const Container = styled.div`
	${container()};
`;

const FooterUpperLayout = styled.div`
	${gridColumnByMargin(2, 48)};
	${spacingRestricted("margin-bottom", "l")};

	display: flex;
	justify-content: space-between;
`

const SocialLinks = styled.nav`
	display: flex;
	flex: grow;
	justify-content: flex-end;
	align-items: center;
	gap: 0.5rem;

	@media ${screen("md")} {
		gap: 1.1rem;
	}
`

const SocialLink = styled(LinkTo)`
  width: 32px;
	aspect-ratio: 1;
	height: 32px;

  @media ${screen("md")} {
		width:  2.6rem;
		height: 2.6rem;

		svg path {
			transition: fill 0.3s ease-in-out;
		}

		&:hover {
			svg path {
				fill: white;
			}
		}
	}
`

const LogoWrapper = styled.div`
	svg {
		max-width: 100%;
		height: auto;
		width: 125px;
	}

	@media ${screen("md")} {
		svg {
			max-width: 100%;
			height: auto;
			${fluidRestricted("top", 125, 225, "designM", "designD")};
		}
	}
`;

const RichTextCustom = styled(RichText)`

	p {
		font-size: 12px;
		line-height: 20px;
	}
`

const NavWrapper = styled.div`
	${gridColumnByMargin(2, 48)};
	${spacingRestricted("margin-bottom", "m")};

	@media ${screen("md")} {
		${grid(12)}
		margin-bottom: 65px;
		grid-column: 3 / span 45;
		gap: 3.6rem;
	}

	@media ${screen("lg")} {
		${grid(20)}
		justify-content: space-between;
		flex-wrap: no-wrap;
		gap: 2rem;
	}
`;

const NavSection = styled(FooterAccordionItem)`
	${spacingRestricted("margin-bottom", "s")};
	${spacingRestricted("padding-bottom", "s")};
	border-bottom: 1px solid ${color("white")};

	@media ${screen("md")} {
		grid-column: span 4;
		border-bottom: none;
		padding-bottom: 0;
		margin-bottom: 0;
	}

	@media ${screen("lg")} {
		width: auto;
		border-bottom: none;
	}
`;

const NavList = styled.ul`
	display: flex;
	flex-direction: column;
	${navigation};
	${spacingRestricted("padding-top", "xxs")};

	li {
		${spacingRestricted("padding-top", "xxs")};
		${spacingRestricted("padding-bottom", "xxs")};

		&:last-child {
			padding-bottom: 0;
		}
	}

	@media ${screen("md")} {
		padding-top: 0;

		li {
			&:first-of-type {
				padding-top: 0;
			}
		}
	}
`;

const NavHeading = styled.li`
	display: none;

	@media ${screen("md")} {
		display: block;
		font-weight: 700;
	}
`;

const NavLink = styled(LinkTo)`
	display: block;
	text-decoration: none;

	span {
		border-bottom: 1px solid ${transparentize(1, color("white"))};
		transition: border-bottom-color 0.1s;
	}

	&:hover {
		span {
			border-bottom-color: ${color("white")};
		}
	}

	@media ${screen("md")} {
		display: inline-block;
		font-weight: ${(props) => (props.isHeading ? "700" : "400")};
	}
`;

const MetaDetails = styled.div`
	${gridColumnByMargin(2, 48)};
	${spacingRestricted("padding-bottom", "m")};

	@media ${screen("md")} {
		${spacingRestricted("padding-bottom", "l")};
	}
`;

const Copyright = styled.div`
	border-top: 1px solid ${color("white")};
	margin-bottom: 1em;
	${gridColumnByMargin(2, 48)};
	${spacingRestricted("padding-top", "m")};

	@media ${screen("md")} {
		${grid(48)};
		${gridColumnByMargin(2, 48)};
		${spacingRestricted("padding-top", "l")};
		border-top: 0;
	}
`;

const PoweredWrapper = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	font-size: 12px;
	line-height: 16px;
	margin-top: 6em;
	white-space: nowrap;

	@media ${screen("md")} {
		margin-top: 0;
		grid-column-start: 32;
		grid-column-end: 49;
	}
`;

const CopyrightRichText = styled(RichText)`
	@media ${screen("md")} {
		grid-column-start: 1;
		grid-column-end: 28;
	}
`;

const PoweredImage = styled(Image)`
	width: 95px;
	margin-left: 1em;
`

const Footer = ({colorTheme = "dark"}) => {
	const {
		mtfFinanceLogo,
		craft: {
			globalSet: {
				footerHeading1,
				footerMenu1,
				footerHeading2,
				footerMenu2,
				footerHeading3,
				footerMenu3,
				footerHeading4,
				footerMenu4,
				footerHeading5,
				footerMenu5,
				footerTopLine,
				footerBottomLine,
			},
		},
	} = useStaticQuery(graphql`
		query footerContent {
			mtfFinanceLogo: file(relativePath: { eq: "logos/mtf-finance-logo.png" }) {
				childImageSharp {
					fluid {
						aspectRatio
						base64
						src
						srcSet
						sizes
					}
				}
			}
			craft {
				globalSet {
					... on CraftCMS_footer_GlobalSet {
						footerHeading1
						footerMenu1 {
							... on CraftCMS_footerMenu1_footerMenuLink_BlockType {
								footerMenuLinkItem {
									element {
										slug
										uri
									}
									ariaLabel
									customText
									target
									text
									title
									type
									url
								}
							}
						}
						footerHeading2
						footerMenu2 {
							... on CraftCMS_footerMenu2_footerMenuLink_BlockType {
								footerMenuLinkItem {
									element {
										slug
										uri
									}
									ariaLabel
									customText
									target
									text
									title
									type
									url
								}
							}
						}
						footerHeading3
						footerMenu3 {
							... on CraftCMS_footerMenu3_footerMenuLink_BlockType {
								footerMenuLinkItem {
									element {
										slug
										uri
									}
									ariaLabel
									customText
									target
									text
									title
									type
									url
								}
							}
						}
						footerHeading4
						footerMenu4 {
							... on CraftCMS_footerMenu4_footerMenuLink_BlockType {
								footerMenuLinkItem {
									element {
										slug
										uri
									}
									ariaLabel
									customText
									target
									text
									title
									type
									url
								}
							}
						}
						footerHeading5
						footerMenu5 {
							... on CraftCMS_footerMenu5_footerMenuLink_BlockType {
								footerMenuLinkItem {
									element {
										slug
										uri
									}
									ariaLabel
									customText
									target
									text
									title
									type
									url
								}
							}
						}
						footerTopLine
						footerBottomLine
					}
				}
			}
		}
	`);

	return (
		<FooterEl colorTheme={colorTheme}>
			<Container>
				<FooterUpperLayout>
					<LogoWrapper>
						<LinkTo aria-label="Home" url="/">
							<Logo />
						</LinkTo>
					</LogoWrapper>

					<SocialLinks>
						<SocialLink key="fb" url="https://www.facebook.com/TheLendingPeople">  <FacebookIcon  /> </SocialLink>
						<SocialLink key="ex" url="https://www.twitter.com/lendingpeople">   <TwitterXIcon  /> </SocialLink>
						<SocialLink key="ig" url="https://www.instagram.com/lendingpeople"> <InstagramIcon /> </SocialLink>
					</SocialLinks>
				</FooterUpperLayout>

				<NavWrapper>
					<NavSection
						heading={
							footerHeading1 ?? footerMenu1?.[0]?.footerMenuLinkItem?.text
						}>
						<NavList>
							{footerHeading1 && <NavHeading>{footerHeading1}</NavHeading>}
							{footerMenu1.map((menuItem, i) => {
								if (menuItem?.footerMenuLinkItem?.url) {
									const isHeading = !footerHeading1 && i === 0;
									return (
										<li key={i}>
											<NavLink
												url={`${menuItem.footerMenuLinkItem.url}/`}
												isHeading={isHeading}
												target={menuItem.footerMenuLinkItem.target}
												linkType={menuItem.footerMenuLinkItem.type}
												element={menuItem.footerMenuLinkItem?.element}
												external={menuItem.footerMenuLinkItem.type !== "entry"}
												key={`foot1_${i}`}>
												<span>{menuItem.footerMenuLinkItem.text}</span>
											</NavLink>
										</li>
									);
								}
							})}
						</NavList>
					</NavSection>
					<NavSection
						heading={
							footerHeading2 ?? footerMenu2?.[0]?.footerMenuLinkItem?.text
						}>
						<NavList>
							{footerHeading2 && <NavHeading>{footerHeading2}</NavHeading>}
							{footerMenu2.map((menuItem, i) => {
								if (menuItem?.footerMenuLinkItem?.url) {
									const item = menuItem?.footerMenuLinkItem
									const isHeading = !footerHeading2 && i === 0;
									const isAnchor = item.url.includes('#');

									return (
										<li key={i}>
											{ isAnchor
												? <a href={item.url}><span>{ item.text }</span></a>
												: <NavLink
														url={`${menuItem.footerMenuLinkItem.url}/`}
														isHeading={isHeading}
														target={menuItem.footerMenuLinkItem.target}
														linkType={menuItem.footerMenuLinkItem.type}
														element={menuItem.footerMenuLinkItem?.element}
														external={menuItem.footerMenuLinkItem.type !== "entry"}
														key={`foot2_${i}`}>
														<span>{menuItem.footerMenuLinkItem.text}</span>
													</NavLink>
											}
										</li>
									);
								}
							})}
						</NavList>
					</NavSection>
					<NavSection
						heading={
							footerHeading3 ?? footerMenu3?.[0]?.footerMenuLinkItem?.text
						}>
						<NavList>
							{footerHeading3 && <NavHeading>{footerHeading3}</NavHeading>}
							{footerMenu3.map((menuItem, i) => {
								if (menuItem?.footerMenuLinkItem?.url) {
									const isHeading = !footerHeading3 && i === 0;
									return (
										<li key={i}>
											<NavLink
												url={`${menuItem.footerMenuLinkItem.url}/`}
												isHeading={isHeading}
												target={menuItem.footerMenuLinkItem.target}
												linkType={menuItem.footerMenuLinkItem.type}
												element={menuItem.footerMenuLinkItem?.element}
												external={menuItem.footerMenuLinkItem.type !== "entry"}
												key={`foot3_${i}`}>
												<span>{menuItem.footerMenuLinkItem.text}</span>
											</NavLink>
										</li>
									);
								}
							})}
						</NavList>
					</NavSection>
					<NavSection
						heading={
							footerHeading4 ?? footerMenu4?.[0]?.footerMenuLinkItem?.text
						}>
						<NavList>
							{footerHeading4 && <NavHeading>{footerHeading4}</NavHeading>}
							{footerMenu4.map((menuItem, i) => {
								if (menuItem?.footerMenuLinkItem?.url) {
									const isHeading = !footerHeading4 && i === 0;
									return (
										<li key={i}>
											<NavLink
												url={`${menuItem.footerMenuLinkItem.url}/`}
												isHeading={isHeading}
												target={menuItem.footerMenuLinkItem.target}
												linkType={menuItem.footerMenuLinkItem.type}
												element={menuItem.footerMenuLinkItem?.element}
												external={menuItem.footerMenuLinkItem.type !== "entry"}
												key={`foot4_${i}`}>
												<span>{menuItem.footerMenuLinkItem.text}</span>
											</NavLink>
										</li>
									);
								}
							})}
						</NavList>
					</NavSection>
					<NavSection
						heading={
							footerHeading5 ?? footerMenu5?.[0]?.footerMenuLinkItem?.text
						}>
						<NavList>
							{footerHeading5 && <NavHeading>{footerHeading5}</NavHeading>}
							{footerMenu5.map((menuItem, i) => {
								if (menuItem?.footerMenuLinkItem?.url) {
									const isHeading = !footerHeading5 && i === 0;
									return (
										<li key={i}>
											<NavLink
												url={`${menuItem.footerMenuLinkItem.url}/`}
												isHeading={isHeading}
												target={menuItem.footerMenuLinkItem.target}
												linkType={menuItem.footerMenuLinkItem.type}
												element={menuItem.footerMenuLinkItem?.element}
												external={menuItem.footerMenuLinkItem.type !== "entry"}
												key={`foot5_${i}`}>
												<span>{menuItem.footerMenuLinkItem.text}</span>
											</NavLink>
										</li>
									);
								}
							})}
						</NavList>
					</NavSection>
				</NavWrapper>
				<MetaDetails>
					<RichTextCustom text={footerTopLine} small={true} />
				</MetaDetails>
				<Copyright>
					<CopyrightRichText text={footerBottomLine} metaSmall={true} />
					<PoweredWrapper>
						Powered by
						<LinkTo url="https://www.mtf.co.nz/" target="_blank">
							{mtfFinanceLogo && (
								<PoweredImage fluid={mtfFinanceLogo.childImageSharp.fluid} />
							)}
						</LinkTo>
					</PoweredWrapper>
				</Copyright>
			</Container>
		</FooterEl>
	);
};

Footer.propTypes = {
	siteTitle: PropTypes.string,
};

Footer.defaultProps = {
	siteTitle: ``,
};

export default Footer;
